@font-face{
  font-family: "Made Tommy Soft";
  src: url("./assets/fonts/MADE_Tommy_Soft_Regular.woff") format("woff")
}

@font-face{
  font-family: "Made Tommy Soft Bold";
  src: url("./assets/fonts/MADE_Tommy_Soft_Bold.woff") format("woff")
}

body{
  margin: 0;
  font-family: 'Made Tommy Soft';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #031E29;
  color: white;
  overflow: hidden;
}

header{
  display: flex;
  justify-content: center;
  width: 100%
}

header a{
  margin-top: 41px;
}

#Logo {
  width: 221.75px;
  height: 41px;
  cursor: pointer
}

#Container{
  width: 100%;
  height: 100px;
  margin-top: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

input{
  width: 180px;
  height: 75px;
  border: 4px solid #277C9E;
  border-radius: 10px;
  margin-right: 25px;
  font-size: 40px;
  display: flex;
  text-align: center;
  padding: 0 20px;
}

.Dropdown{
  width: 140px;
  height: 80px;
  border: 4px solid #277C9E;
  border-radius: 10px;
  font-family: 'Made Tommy Soft Bold';
  font-size: 40px;
  background: #031E29;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer
}

.Dropdown:hover button.Secondary{
  display: flex !important
}

.Dropdown:hover{
  height: 160px
}

.Dropdown:hover .Arrow{
  transform: rotate(180deg)
}

button{
  width: 140px;
  all: unset;
  display: flex;
  height: 75px;
  align-items: center;
  padding: 0 16px;
}

button.Secondary{
  font-family: 'Made Tommy Soft';
  color: #D0D2D6;
  align-self: flex-start;
  position: relative;
  width: 100%;
  padding: 0 0 0 16px;
  display: none
}

.Arrow{
  height: 12px;
  width: 16px;
  margin-left: 15px
}

span{
  margin: -20px 28px 0 28px;
  font-size: 38px;
}

#Icons, footer{
  position: absolute
}

#Icons{
  right: 50px;
  bottom: 50px
}

#Icons a{
  margin-left: 80px
}

#Icons a img{
  height: 44px;
  width: 44px
}

footer{
  font-size: 18px;
  left: 160px;
  bottom: 38px
}

@media only screen and (max-width: 1250px) {
  #Icons a img{
    height: 40px;
    width: 40px
  }
  #Icons a {
    margin-left: 40px;
  }
  #Icons {
    right: 30px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 940px) {
  .App{
    background-image: none !important;
  }
  #Container{
    flex-direction: column
  }
  #Logo{
    display: none;
  }
  #Logo2{
    display: flex !important;
    width: 59px;
    height: 48px
  }
  .Dropdown{
    height: 55px
  }
  .Dropdown:hover{
    height: 110px
  }
  input{
    height: 55px;
    width: 150px;
    font-size: 30px;
    padding: 0
  }
  button{
    height: 55px;
    width: 80px;
    font-size: 30px;
  }
  span{
    margin: 28px 0;
    margin: 0px 0px 40px 0px;
  }
  footer{
    display: none
  }
  .Moon{
    display: flex !important;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 22.5%;
  }
  .Dropdown{
    width: 110px
  }
  .Arrow{
    height: 8px;
    width: 12px
  }
  #Icons {
    bottom: 27px;
    z-index: 1;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
  }
  #Icons a img {
    width: 26px;
    height: 26px
  }
  #Icons a:first-child{
    margin: 0
  }
  .Group{
    min-height: 110px;
  }
}

@media only screen and (max-height: 750px) {
  #Icons {
    right: 60px;
    bottom: 40px;
  }
  #Icons a {
    margin-left: 35px;
  }
  #Icons a img{
    height: 35px;
    width: 35px
  }
}